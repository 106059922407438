<template>
  <b-row
    v-if="typeof $route.meta == 'function' ? ($route.meta($route).breadcrumb || $route.meta($route).pageTitle) : false"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      :md="$store.state.app.createButtonAndGlobalSearch.isActive ? 7 : 12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta($route).pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item v-if="isRoled()" to="/">
                <unicon width="20" name="home-alt" fill="royalblue" />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta($route).breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                <unicon width="20" :fill="item.active ? 'gray' : 'royalblue'" class="pr-1" v-if="item.icon" :name="item.icon" />
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <!-- Content Right -->
    <b-col
      v-if="$store.state.app.createButtonAndGlobalSearch.isActive"
      class="content-header-right text-right mb-1"
      md="5"
      cols="12"
    >
      <div class="d-flex">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="global-search"
            type="search"
            :placeholder="$store.state.app.createButtonAndGlobalSearch.placeHolder"
            v-model="$store.state.app.createButtonAndGlobalSearch.value"
            @input="$store.state.app.createButtonAndGlobalSearch.filterFunc()"
          />
          <b-input-group-append is-text>
            <unicon width="16" fill="gray" name="search" />
          </b-input-group-append>
        </b-input-group>
        <b-button
            v-if="$store.state.app.createButtonAndGlobalSearch.isbuttonActive"
          @click="$store.state.app.createButtonAndGlobalSearch.fetchingFunction(true)"
          variant="primary"
          size="sm"
          class="ml-2 px-2 d-flex align-items-center text-nowrap"
        >
          {{ $store.state.app.createButtonAndGlobalSearch.text }}
          <unicon width="18" height="20" name="plus" class="pl-1" fill="#fff"></unicon>
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>
<style lang="scss">
.input-group-text {
  padding: 5px 1rem;
}
</style>
<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BButton, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from "@/auth/utils";
import { Admin } from "@/router";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  methods : {
    isRoled(){
      return getUserData().role == Admin
    },
  }
}
</script>
