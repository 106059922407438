<template>
  <b-navbar-nav class="nav">
    <dark-Toggler />
    <notification-dropdown />
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav,
} from 'bootstrap-vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import DarkToggler from './components/DarkToggler.vue'

export default {
  components: {
    // Navbar Components
    BNavbarNav,
    NotificationDropdown,
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
