import { Responder, Admin, Teacher } from '@/router'
export default [
  {
    title: 'الرئيسية',
    route: '/home',
    icon: 'home-alt',
    roles: [Admin]
  },
  {
    header: 'إدارة المحتوى',
    roles: [Admin]
  },
  {
    title: 'المواد والوحدات الدراسية',
    route: '/subjects-units',
    icon: 'books',
    roles: [Admin]
  },
  {
    title: 'الوحدات الدراسية',
    route: '/subjects-units/units',
    icon: 'books',
    roles: [Teacher]
  },
  {
    title: 'الدروس',
    route: '/lessons',
    icon: 'bookmark',
    roles: [Admin, Teacher]
  },
  {
    title: 'بنك الأسئلة',
    route: '/question-bank',
    icon: 'file-question-alt',
    roles: [Admin, Teacher]
  },
  {
    title: 'الجلسات الإمتحانية',
    route: '/exam-sessions',
    icon: 'question-circle',
    roles: [Admin, Teacher]
  },
  {
    title: 'الإعلانات',
    route: '/ads',
    icon: 'megaphone',
    roles: [Admin]
  },
  {
    title: 'الإشعارات',
    route: '/notifications',
    icon: 'bell',
    roles: [Admin]
  },
  {
    header: 'المالية والأرباح',
    roles: [Admin]
  },
  {
    title: 'الرموز والحزم',
    route: '/codes-packages',
    icon: 'qrcode-scan',
    roles: [Admin]
  },
  {
    title: 'كشف حساب',
    icon: 'bill',
    roles: [Admin],
    children: [
      {
        title: 'نقاط البيع',
        route: '/invoice/3',
        roles: [Admin],
      },
      {
        title: 'مجيبو الأسئلة',
        route: '/invoice/2',
        roles: [Admin],
      },
      {
        title: 'الأساتذة',
        route: '/invoice/1',
        roles: [Admin],
      },
    ],
  },
  {
    header: 'الحسابات',
    roles: [Admin]
  },
  {
    title: 'مستخدمو التطبيق',
    route: '/user/5',
    icon: 'users-alt',
    roles: [Admin]
  },
  {
    title: 'الأساتذة',
    route: '/user/2',
    icon: 'user-circle',
    roles: [Admin]
  },
  {
    title: 'نقاط البيع',
    route: '/user/4',
    icon: 'money-withdrawal',
    roles: [Admin]
  },
  {
    title: 'مجيبو الأسئلة',
    route: '/user/3',
    icon: 'envelope-question',
    roles: [Admin]
  },
  {
    header: 'الإعدادات',
    roles: [Admin]
  },
  {
    title: 'مستخدمو اللوحة',
    route: '/user/1',
    icon: 'user-square',
    roles: [Admin]
  },
  {
    title: 'النسب',
    route: '/rates',
    icon: 'moneybag',
    roles: [Admin]
  },
  {
    title: 'الأسئلة الواردة',
    route: '/received-questions',
    icon: 'bookmark',
    roles: [Responder]
  },
  {
    title: 'سجل الإجابات',
    route: '/answers-record',
    icon: 'file-question-alt',
    roles: [Responder]
  },
]
