var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isRoled)?_c('router-link',{attrs:{"to":_vm.linkProps.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(_vm.canViewVerticalNavMenuLink(_vm.item))?_c('li',{staticClass:"nav-item aside-nav-items",class:{
        'active': isActive,
        'disabled': _vm.item.disabled
        }},[_c('a',{staticClass:"d-flex align-items-center",attrs:{"href":href},on:{"click":navigate}},[_c('unicon',{attrs:{"name":_vm.item.icon || 'circle',"width":"18","fill":"#5E5873"}}),_c('span',{staticClass:"menu-title text-truncate",staticStyle:{"color":"#5E5873"}},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.tag)?_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":"","variant":_vm.item.tagVariant || 'primary'}},[_vm._v(" "+_vm._s(_vm.item.tag)+" ")]):_vm._e()],1)]):_vm._e()]}}],null,false,3506553314)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }