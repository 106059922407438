<template>
  <b-nav-item-dropdown

    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    left
  >
    <template #button-content>
      <b-badge v-if="notificationCount > 0"
               variant="danger" 
               class="rounded-circle position-relative" 
               style="top: -8px; right: 12px">
               {{notificationCount}}
      </b-badge>
      <unicon
        wdith="18"
        :fill="`${isDark ? '#fff' : '#5E5873'}`"
        name="bell"
      />
    </template>
    
    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notificationsDash.length > 0"
      v-once
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notificationsDash"
        :key="notification.id"
      >
        <b-media @click="notification.posId != null ? goto(notification.posId) : ''">
          <p class="media-heading" style="display: flex; justify-content: space-between;">
            <span class="font-weight-bolder">
              {{ notification.message }}
            </span>
            <b-badge
               v-if="!notification.isRead"
               variant="danger" 
               class="rounded-circle position-relative" 
               >
               {{" "}}
            </b-badge>
            <!-- <unicon
                    @click="removeNotification(notification.id)"
                    name="trash-alt"
                    fill="#5E5873"
                    width="40"
                    height="25"
            ></unicon>      -->
          </p>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>


    <!-- Cart Footer -->
    <li class="dropdown-menu-footer" v-if="notificationsDash.length > 0">
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="read()"
      >تمييز كمقروء</b-button>
    </li>
    <div v-else class="p-1">
      لا توجد إشعارات جديدة
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink,  BButton, 
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return {
      isDark,
    } 
  },
  
  computed:{
    ...mapGetters(["notificationsDash","notificationCount"])
  },
  methods:{
    ...mapActions(["fetchNotificationsDash","removeNotificationsDash","markAsRead"]),
    read(){
      this.markAsRead()
    },
    removeNotification(id){
      this.removeNotificationsDash(id)
    },
    goto(posId){
      this.$router.push({ path: `/user/4/${posId}/details`}).catch(()=>{});
    }
  },
  created(){
    this.fetchNotificationsDash();
  },
}
</script>
